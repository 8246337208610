/* *{
    font-family:'Times New Roman', Times, serif;
} */

.orderTable {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

.orderTable caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}
.orderTable .orderTr {
  background-color: #fff;
  border: 1px solid #e1e5e9;
  padding: 0.35em;
  border-radius: 3px;
}
.orderTable .orderTablehead .orderTr:first-child {
  border: 1px solid #364255;
}
.orderTable .orderTh,
.orderTable .ordertTd {
  padding: 1.625em;
  text-align: center;
  color: #9da9b9;
  font-size: 14px;
  /* font-family: cursive; */
}

/* .orderTable .ordertTd:nth-child(4) {
	font-size: 18px;
} */

.orderTable .orderTh {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background: #364255;
  color: #fff;
}

.orderTable tbody .orderTr .ordertTd .btn-invoice {
  background: #364255;
  color: #fff;
  font-size: 13px;
  padding: 10px 20px;
  border: 0;
  /* font-family: cursive; */
}

/* tbody .orderTr:nth-child(even) {
	background-color: #eee;
}

tbody .orderTr:nth-child(odd) {
	background-color: #fff;
} */

@media screen and (max-width: 800px) {
  .orderTable {
    border: 0;
  }
  .orderTable caption {
    font-size: 1.3em;
  }
  .orderTable .orderTablehead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
    padding: 0;
  }
  .orderTable .orderTr {
    border-bottom: 3px solid #e1e5e9;
    display: block;
    margin-bottom: 0.625em;
  }
  .orderTable .orderTh,
  .orderTable .ordertTd {
    padding: 0.625em;
  }
  .orderTable .ordertTd {
    border-bottom: 1px solid #e1e5e9;
    display: block;
    font-size: 0.8em;
    text-align: right;
    color: #9da9b9;
  }
  .orderTable .ordertTd::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    color: #656971;
  }
  .orderTable .ordertTd:last-child {
    border-bottom: 0;
  }
  .orderTable .ordertTd:nth-child(4) {
    font-size: 0.8em;
  }
}

/* accordian css  */

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

.accordion {
  width: 400px;
}

.custom-accordion {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.custom-accordion:hover {
  background-color: #f0f0f0;
}

.custom-accordion-title {
  padding: 16px;
}

.custom-accordion-description {
  padding: 1px 16px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
}

.accordian-down-arrow {
  float: right;
}

.custom-accordion.checked > .custom-accordion-description {
  max-height: fit-content;
}
.custom-accordion.checked .accordian-down-arrow {
  rotate: -90deg;
}

.custom-accordion input[type="checkbox"] {
  display: none;
}

.custom-accordion
  input[type="checkbox"]:checked
  + .custom-accordion-title
  .opened {
  display: block;
}

.custom-accordion
  input[type="checkbox"]:checked
  + .custom-accordion-title
  .closed {
  display: none;
}

/* accordian css end  */
