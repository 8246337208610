@import url("https://fonts.googleapis.com/css?family=Ubuntu:400,400i,700,700i");
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  word-break: break-all;
}

html {
  font-size: 10px;
}

body {
  color: #6e6e6e;
  font-family: "Ubuntu", sans-serif;
  font-size: 1.6rem;
}

ul {
  list-style: none;
}

a,
a:link,
a:visited {
  color: #4d4d4d;
  text-decoration: none;
}

nav {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1rem 2rem;
}

.main-nav-holder {
  transition: 0.2s;
  /* width: 100%; */
}

.main-nav {
  display: flex;
}
.main-nav > li {
  border: 0.1rem solid #e6e6e6;
  margin: 0 0.4rem 0 0;
  position: relative;
  min-width: 150px;
  background-color: #fff;
}
.main-nav > li > a,
.main-nav > li > a:link,
.main-nav > li > a:visited {
  display: block;
  padding: 0.8rem 1.5rem;
}
.main-nav > li > a:hover,
.main-nav > li > a:link:hover,
.main-nav > li > a:visited:hover {
  background: #e6e6e6;
}
.main-nav > li.has-submenu > label {
  color: #4d4d4d;
  cursor: pointer;
  display: block;
  padding: 1.5rem 2.4rem 1.5rem 1.9rem;
  position: relative;
}
.main-nav > li.has-submenu > label:hover {
  background: #e6e6e6;
}
.main-nav > li.close-nav {
  display: none;
}
.main-nav > li.active > a,
.main-nav > li.active > a:link,
.main-nav > li.active > a:visited,
.main-nav > li.active > label {
  background: #e6e6e6;
}

.menu-caret {
  border-bottom: 0.6rem solid transparent;
  border-top: 0.6rem solid transparent;
  border-left: 0.6rem solid gray;
  display: inline-block;
  line-height: 0;
  margin: 0 -0.3rem 0 1.2rem;
  position: absolute;
  right: 1.4rem;
  top: 1.9rem;
  transform-origin: 0.3rem 50%;
  transition: 0.2s;
}

.submenu {
  display: flex;
  padding: 1rem 0 0;
  opacity: 0;
  position: absolute;
  transition: 0.2s;
  visibility: hidden;
  transform: translateY(-5rem);
  white-space: nowrap;
}
.submenu > li {
  position: relative;
}
.submenu > li > a,
.submenu > li > a:link,
.submenu > li > a:visited {
  color: gray;
  display: block;
  padding: 0.7rem 1.6rem;
}
.submenu > li > a:hover {
  color: black;
}
.submenu > li::after {
  content: "|";
  position: absolute;
  top: 0.7rem;
  right: -0.3rem;
}
.submenu > li:last-of-type::after {
  display: none;
}

.check-toggle {
  display: none;
}
.check-toggle:checked ~ label {
  background: #e6e6e6;
}
.check-toggle:checked ~ .menu-caret {
  transform: rotate(90deg);
}
.check-toggle:checked ~ .submenu {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.hamburgermenu,
.menu-overlay {
  display: none;
}

.avatar {
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.4);
  cursor: pointer;
  display: inline-block;
  height: 3.6rem;
  margin: 0 0 0 1rem;
  overflow: hidden;
  width: 3.6rem;
}
.avatar img {
  width: 100%;
}

.account-menu {
  border-top: 0.3rem solid #cccccc;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 5rem;
  transition: 0.2s;
  transform: translateY(-4rem);
  visibility: hidden;
  z-index: 1;
}
.account-menu li {
  margin: 0 0 0.1rem;
}
.account-menu li a,
.account-menu li a:link,
.account-menu li a:visited {
  background: whitesmoke;
  display: block;
  padding: 0.5rem 1.5rem;
}
.account-menu li a:hover {
  background: #e6e6e6;
}
.account-menu::before {
  border-bottom: 0.8rem solid #cccccc;
  border-left: 0.6rem solid transparent;
  border-right: 0.6rem solid transparent;
  content: "";
  position: absolute;
  top: -1rem;
  right: 1rem;
}

.check-toggle:checked ~ .account-menu {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
.stickyproductdescimg {
  position: sticky;
  top: 0;
  max-height: 110vh;
}

@media (max-width: 720px) {
  .filterNav {
    display: none;
  }
  .stickyproductdescimg {
    position: inherit;
  }
}

.hearticon {
  position: absolute;
  top: 225px;
  right: 139px;
  font-size: 30px;
}

.logininp {
  outline: none;
  border: none;
  border-radius: 0;
  width: 100%;
  border: 1px solid rgb(203, 198, 198);
  padding: 10px;
  margin-bottom: 15px;
}
.LoginTitle {
  font-size: x-large;
  color: #333333;
  padding: 10px;
  color: rgba(34, 44, 55);
  font-weight: 600;
}
.loginbtn {
  width: 100%;
  outline: none;
  border: none;
  padding: 10px;
  color: #fff;
  font-weight: 600;
  /* background-color: rgb(88, 105, 135); */
  background: linear-gradient(
    0.25turn,
    rgba(34, 44, 55),
    rgba(61, 74, 95),
    rgb(88, 105, 135)
  );
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #313d4e;
}

.procontainer:hover .overlay {
  opacity: 1;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
/* @charset "utf-8"; */

.checkoInput {
  width: 100%;
  border-radius: 2px;
  outline: none;
  padding: 10px;
  border: none !important;
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700,600);

html,
html a {
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

/* body {
  background-color: #fff;
  color: #666;
  font-family: "Open Sans", sans-serif;
  font-size:1.3em!important;
  margin: 0 auto;
} */

a {
  border: 0 none;
  outline: 0;
  text-decoration: none;
}

strong {
  font-weight: bold;
}

p {
  margin: 0.75rem 0 0;
}

h1 {
  /* font-size: 0.75rem; */
  font-weight: normal;
  margin: 0;
  padding: 0;
}

input,
button {
  border: 0 none;
  outline: 0 none;
}

button {
  background-color: #666;
  color: #fff;
}

/* button:hover,
button:focus {
  background-color: #555;
} */

.basket-module,
.basket-labels,
.basket-product {
  width: 100%;
}
.basket {
  /* width: 100%;
   */
  background: #fff;
}
/* .aside{
  
} */

input,
button,
.basket,
.basket-module,
.basket-labels,
.item,
.price,
.quantity,
.subtotal,
.basket-product,
.product-image,
.product-details {
  float: left;
}

.price:before,
/* .subtotal:before, */
/* .subtotal-value:before, */
.total-value:before,
.promo-value:before {
  content: "₹";
}

.hide {
  display: none;
}

main {
  clear: both;
  /* font-size: 0.75rem; */
  margin: 0 auto;
  overflow: hidden;
  padding: 1rem 0;
  width: 960px;
}

.basket,
aside {
  padding: 0 1rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* .basket {
  width: 70%;
} */

.basket-module {
  color: #111;
}

/* label {
  display: block;
  margin-bottom: 0.3125rem;
  font-size: 1.4em;
} */

.promo-code-field {
  border: 1px solid #ccc;
  padding: 0.5rem;
  text-transform: uppercase;
  transition: all 0.2s linear;
  width: 48%;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.promo-code-field:hover,
.promo-code-field:focus {
  border: 1px solid #999;
}

.promo-code-cta {
  border-radius: 4px;
  /* font-size: 0.625rem; */
  margin-left: 0.625rem;
  padding: 0.6875rem 1.25rem 0.625rem;
}

.basket-labels {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-top: 1.625rem;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.cart-Listt-heading {
  color: #111;
  display: inline-block;
  padding: 0.625rem 0;
}

.cart-Listt-heading.price:before,
.cart-Listt-heading.subtotal:before {
  /* content: ""; */
}

.item {
  width: 55%;
}

.price,
.quantity,
.subtotal {
  width: 15%;
}

.subtotal {
  text-align: right;
}

.remove {
  bottom: 1.125rem;
  float: right;
  position: absolute;
  right: 0;
  text-align: right;
  width: 45%;
}

.remove button {
  background-color: transparent;
  color: #777;
  float: none;
  text-decoration: underline;
  text-transform: uppercase;
}

.item-heading {
  padding-left: 4.375rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.basket-product {
  border-bottom: 1px solid #ccc;
  padding: 1rem 0;
  position: relative;
}

.product-image {
  width: 35%;
}

.product-details {
  width: 65%;
}

.product-frame {
  border: 1px solid #aaa;
}

.product-details {
  padding: 0 1.5rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.quantity-field {
  background-color: #ccc;
  border: 1px solid #aaa;
  border-radius: 4px;
  /* font-size: 0.625rem; */
  padding: 2px;
  width: 3.75rem;
}

aside {
  background: #fff;
  float: right;
  position: relative;
  /* width: 30%; */
}

.summary {
  background-color: #eee;
  border: 1px solid #aaa;
  padding: 1rem;
  /* position: fixed; */
  width: 330px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.summary-total-items {
  color: #666;
  /* font-size: 0.875rem; */
  text-align: center;
}

.summary-subtotal,
.summary-total {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  clear: both;
  margin: 1rem 0;
  overflow: hidden;
  padding: 0.5rem 0;
}

.subtotal-title,
.subtotal-value,
.total-title,
.total-value,
.promo-title,
.promo-value {
  color: #111;
  float: left;
  width: 50%;
}

.summary-promo {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.promo-title {
  float: left;
  width: 70%;
}

.promo-value {
  color: #8b0000;
  float: left;
  text-align: right;
  width: 30%;
}

.summary-delivery {
  padding-bottom: 3rem;
}

.subtotal-value,
.total-value {
  text-align: right;
}

.total-title {
  font-weight: bold;
  text-transform: uppercase;
}

.summary-checkout {
  display: block;
}

.checkout-cta {
  display: block;
  float: none;
  /* font-size: 0.75rem; */
  text-align: center;
  text-transform: uppercase;
  padding: 0.625rem 0;
  width: 100%;
}

.summary-delivery-selection {
  background-color: #ccc;
  border: 1px solid #aaa;
  border-radius: 4px;
  display: block;
  /* font-size: 0.625rem; */
  height: 34px;
  width: 100%;
}
.getNewsH {
  font-size: 2.5rem;
  font-weight: 600;
}
@media screen and (max-width: 640px) {
  .getNewsH {
    font-size: 2rem;
    font-weight: 600;
  }
  aside,
  .basket,
  .summary,
  .item,
  .remove {
    width: 100%;
  }
  .basket-labels {
    display: none;
  }
  .basket-module {
    margin-bottom: 1rem;
  }
  .item {
    margin-bottom: 1rem;
  }
  .product-image {
    width: 40%;
  }
  .product-details {
    width: 60%;
  }
  .price,
  .subtotal {
    width: 33%;
    float: right;
  }
  .quantity {
    text-align: center;
    width: 34%;
  }
  .quantity-field {
    float: none;
  }
  .remove {
    bottom: 0;
    text-align: left;
    margin-top: 0.75rem;
    position: relative;
  }
  .remove button {
    padding: 0;
  }
  .summary {
    margin-top: 1.25rem;
    position: relative;
  }
}

@media screen and (min-width: 641px) and (max-width: 960px) {
  aside {
    padding: 0 1rem 0 0;
  }
  .summary {
    width: 28%;
  }
}

@media screen and (max-width: 960px) {
  main {
    width: 100%;
  }
  .product-details {
    padding: 0 1rem;
  }
}

.cart-info-ection {
  margin: 7px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
section .cart-info-img {
  z-index: 1;
  margin-bottom: -25px;
  width: 11em;
}
section .info-box {
  width: 85%;
  height: 150px;
  background-color: #8191eb;
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  position: relative;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

section .box h1 {
  margin: 20px 0 10px;
  color: var(--mainColor);
  letter-spacing: 5px;
  font: bold 26px sans-serif;
  text-transform: uppercase;
}
section .info-box h4 {
  color: #978888;
  position: relative;
}
section .info-box h4::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: -170px;
  width: 320%;
  height: 2px;
  background-color: var(--mainColor);
}
section .info-box .frameInfoDiv {
  width: 90%;
  /* height: 20px; */
  margin-top: 30px;
  margin-left: 12px;
  /* padding: 25px; */
  background-color: #fff;
  /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative; */
}

.cardd:hover .view {
  height: auto;
  padding: 5px 0;
}
.cardd:hover .favrate {
  opacity: 1;
}
.img-hover {
  transition: 0.3s;
}

.cardd:hover .img-hover {
  scale: 1.1;
}

.quote {
  font-family: Arial, sans-serif;
  /* font-size: 18px; */
  line-height: 2.5;
  margin: 20px;
  padding: 20px;
  padding-bottom: 0px;
  margin-bottom: 0px;

  /* border-left: 5px solid #333; */
  /* background-color: #f9f9f9; */
  max-height: 10em; /* Approximate 5 lines */
  overflow: hidden;
  position: relative;
  margin-top: 0;
  padding-top: 0;
}

.quote p {
  margin: 0;
  padding-right: 1em; /* Create space for the ellipsis */
}

.quote::after {
  /* content: '....'; */
  position: absolute;
  bottom: 13px;
  right: 0;
  padding: 0 5px;
  /* background-color: #f9f9f9; */
}
.qouteheading {
  font-size: 4rem;
  margin-top: 20px;
  margin-left: 30px;
  /* color: #000; */

  padding: 10px;
}
@media screen and (max-width: 960px) {
  .quote {
    font-family: Arial, sans-serif;
    /* font-size: 18px; */
    line-height: 1.8;
    margin: 0;
    padding: 0;
    /* border-left: 5px solid #333; */
    /* background-color: #f9f9f9; */
    max-height: 4em; /* Approximate 5 lines */
    overflow: hidden;
    position: relative;
    margin-top: 0;
    padding-right: 1em;
  }

  .quote::after {
    content: ".....";
    position: absolute;
    bottom: 13px;
    right: 0;
    padding: 0 5px;
    /* background-color: #f9f9f9; */
  }
  .qouteheading {
    margin-top: 0;
    margin-left: 0;
    padding: 0;
    font-size: 18px;
  }
}

@media screen and (min-width: 700px) {
  .product_detail_content {
    display: flex;
    /* / padding: 20px 0; / */
  }

  .product_detail_main {
    flex: 1 0 auto;
    /* / // display: flex; / */
    /* / // align-items: center; / */
    /* / // justify-content: center; / */
    width: 60% !important;
    height: auto;
    margin-right: 10px;
    /* / background: #ccc; / */
    /* / background: linear-gradient(to bottom, #eeeeee 0%,#B7EAE0 100%); / */
  }
  .product_detail_side {
    display: flex;
    align-items: center;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10px;
    width: 40% !important;
    height: 40% !important;
    /* / color: #fff; / */
    /* / background: #30b298; / */
  }
}

@media screen and (max-width: 700px) {
  .product_detail_content {
    width: 100%;
    /* / display: flex; / */
    /* / padding: 20px 0; / */
  }
  .product_detail_side {
    width: 100%;
    height: auto;
    /* / color: #fff; / */
    /* / background: #30b298; / */
  }
  .product_detail_content {
    /* / display: flex; / */
    padding: 20px 0;
  }
}
