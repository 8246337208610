/* src/ProductCard.css */

.product-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    margin: 16px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .product-image {
    width: 100%;
    height: 200px; /* Adjust based on your design */
    object-fit: cover;
  }
  
  .product-details {
    padding: 16px;
    text-align: center;
    flex: 1; /* Ensures the details area takes up remaining space */
  }
  
  .product-title {
    font-size: 1.2rem;
    margin: 0;
  }
  
  .product-price {
    color: #333;
    font-size: 1.1rem;
    margin: 8px 0;
  }
  
  .add-to-cart-btn {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-to-cart-btn:hover {
    background-color: #218838;
  }
  